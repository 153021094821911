






import Vue from 'vue';
import { ProfileModel } from '@/apis/models/AccountModel';
import ManagerApi from '@/apis/ManagerApi';
import { ManagerEntryInfoModel } from '@/apis/models/ManagerModel';

interface Route {
  name: string;
  value: string;
  address: string; // 如果在mamger下就不要加斜線(/group會導不到)
}

export default Vue.extend({
  data() {
    return {
    };
  },
  computed: {
    profile(): ProfileModel {
      return this.$store.state.profile;
    },

  },
  mounted() {
    // [Development] temperarily put manager info here
    ManagerApi.getManagerEntryInfo().then((info: ManagerEntryInfoModel) => {
      this.$store.commit('updateWorkspaceList', info.workspaceList);
      this.$store.commit('changeWorkspace', info.workspaceList[0]);

      if (this.$router.currentRoute.path.startsWith('/manager/workspace')
      && this.$router.currentRoute.params.folderId === undefined) {
        this.$router.push({
          name: 'QuizGroups',
          params: {
            folderId: info.workspaceList[0].quizFolders.local,
          },
        });
      }
    });
  },
});
