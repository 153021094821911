








import Vue from 'vue';

export default Vue.extend({
  name: 'ComingSoon',
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
});
